import React from 'react';
import './OurServices.css';

function OurServices() {
  return (
    <div className="our-services">
      <section className="service">
        <h2>Venture Capital and Private Equity</h2>
        <p>Investment strategies and portfolio management.</p>
      </section>
      <section className="service">
        <h2>Consulting Services</h2>
        <p>Financial data and Fintech IT consulting.</p>
      </section>
      <section className="service">
        <h2>Financial IT Solutions</h2>
        <p>Comprehensive IT solutions for the financial sector.</p>
      </section>
    </div>
  );
}

export default OurServices;
