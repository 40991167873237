//Bearer sk-None-iLnL5DV0HNHmSojbL8AJT3BlbkFJkYp2Ci4m1E1WTcHViQ21
import React, { useState, useEffect, useRef } from 'react';
import Draggable from 'react-draggable';
import './CustomChatbot.css';
import axios from 'axios';

function CustomChatbot() {
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [isFisttime, setIsFirstTime] = useState(true);

  const messagesEndRef = useRef(null); // Ref to scroll to the end of messages

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]); // Scroll to the bottom when messages change

  const handleSend = async (event) => {
    event.preventDefault();
    if (!userInput.trim()) return;

    const userMessage = { role: 'user', content: userInput };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setUserInput('');
    setIsTyping(true);

    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-3.5-turbo',
          messages: [{ role: 'user', content: userInput }],
        },
        {
          headers: {
            Authorization: `Bearer sk-None-iLnL5DV0HNHmSojbL8AJT3BlbkFJkYp2Ci4m1E1WTcHViQ21`, // Ensure your API key is correct
            'Content-Type': 'application/json',
          },
        }
      );

      const botMessageContent = response.data.choices[0].message.content.trim();
      displayBotMessage(botMessageContent);
    } catch (error) {
      console.error('Error fetching response:', error);
      const errorMessage = {
        role: 'assistant',
        content: 'Sorry, something went wrong.',
      };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
      setIsTyping(false);
    }
  };

  const displayBotMessage = (content) => {
    let currentMessage = '';
    setMessages((prevMessages) => [
      ...prevMessages,
      { role: 'assistant', content: 'Welcome To TT,How can I help you today !' },
    ]);

    content.split('').forEach((char, index) => {
      setTimeout(() => {
        currentMessage += char;
        setMessages((prevMessages) => [
          ...prevMessages.slice(0, -1),
          { role: 'assistant', content: currentMessage },
        ]);
        if (index === content.length - 1) setIsTyping(false);
      }, index * 50); // Adjust the timing for the typing effect (50ms per character)
    });
  };

  const toggleVisibility = () => {
    if (!isVisible & isFisttime) {
      
      setMessages((prevMessages) => [
        ...prevMessages,
        { role: 'assistant', content: 'Welcome To TT,How can I help you today!' },
      ]);
      setUserInput('');
      setIsFirstTime(!isFisttime);
    }
    setIsVisible(!isVisible);

  };

  return (
    <div>
      <button className="chatbot-toggle-button" onClick={toggleVisibility}>
        {isVisible ? 'Hide Chat' : 'Show Chat'}
      </button>
      {isVisible && (
        <Draggable>
          <div className="chatbot-container">
            <div className="chatbot-messages">
              {messages.map((msg, index) => (
                <div key={index} className={`chatbot-message ${msg.role}`}>
                  {msg.content}
                </div>
              ))}
              {isTyping && <div className="chatbot-message typing">...</div>}
              <div ref={messagesEndRef} /> {/* Empty div to scroll into view */}
            </div>
            <form onSubmit={handleSend} className="chatbot-input-container">
              <input
                type="text"
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                placeholder="Type a message..."
                className="chatbot-input"
              />
              <button type="submit" className="chatbot-send-button">
                Send
              </button>
            </form>
          </div>
        </Draggable>
      )}
    </div>
  );
}

export default CustomChatbot;
