import React, { useRef, useEffect } from 'react';
import CompanyIntroduction from '../components/CompanyIntroduction';
import './HomePage.css';

function HomePage() {
  const firstVideoRef = useRef(null);
  const secondVideoRef = useRef(null);
  const introRef = useRef(null);

  useEffect(() => {
    if (firstVideoRef.current) {
      firstVideoRef.current.playbackRate = 0.5; // Set the desired playback speed
      firstVideoRef.current.addEventListener('ended', handleFirstVideoEnd);
    }

    if (secondVideoRef.current) {
      secondVideoRef.current.playbackRate = 0.7; // Set the desired playback speed
      secondVideoRef.current.addEventListener('ended', handleSecondVideoEnd);
      secondVideoRef.current.style.display = 'none';
    }

    return () => {
      if (firstVideoRef.current) {
        firstVideoRef.current.removeEventListener('ended', handleFirstVideoEnd);
      }
      if (secondVideoRef.current) {
        secondVideoRef.current.removeEventListener('ended', handleSecondVideoEnd);
      }
    };
  }, []);

  const handleFirstVideoEnd = () => {
    if (firstVideoRef.current) {
      firstVideoRef.current.style.display = 'none';
    }
    if (secondVideoRef.current) {
      secondVideoRef.current.style.display = 'block';
      secondVideoRef.current.play();
    }
  };

  const handleSecondVideoEnd = () => {
    if (secondVideoRef.current) {
      secondVideoRef.current.style.display = 'none';
    }
    if (firstVideoRef.current) {
      firstVideoRef.current.style.display = 'block';
      firstVideoRef.current.play();
    }
  };

  const scrollToIntro = () => {
    if (introRef.current) {
      introRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="home-page">
      <section className="hero-section">
        <video ref={firstVideoRef} autoPlay muted>
          <source src="/background.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video ref={secondVideoRef} muted>
          <source src="/background.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <h1>Welcome to Tendency Tech</h1>
        <p>Your partner in Financial IT and AI solutions</p>
        <button onClick={scrollToIntro}>Learn More</button>
      </section>
      <section ref={introRef} className="introduction">
        <h2>Introduction</h2>
        <p>TT Tech LTD is a new company founded by a Financial IT and AI expert, focusing on Venture Capital, consulting services, and IT solutions.</p>
        <CompanyIntroduction />
      </section>
      <section className="key-highlights">
        <h2>Our Focus Areas</h2>
        <div className="highlight">
          <h3>Venture Capital and Private Equity</h3>
          <p>Investment strategies and portfolio management.</p>
        </div>
        <div className="highlight">
          <h3>Consulting Services</h3>
          <p>Financial data and Fintech IT consulting.</p>
        </div>
        <div className="highlight">
          <h3>Financial IT Solutions</h3>
          <p>Comprehensive IT solutions for the financial sector.</p>
        </div>
      </section>
    </div>
  );
}

export default HomePage;
