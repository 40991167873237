import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import AboutUs from './pages/AboutUs';
import OurServices from './pages/OurServices';
import NewsAndInsights from './pages/NewsAndInsights';
import ContactUs from './pages/ContactUs';
import CustomChatbot from './components/CustomChatbot'; // Import the CustomChatbot component
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <div className="content">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/our-services" element={<OurServices />} />
            <Route path="/news-and-insights" element={<NewsAndInsights />} />
            <Route path="/contact-us" element={<ContactUs />} />
          </Routes>
        </div>
        <CustomChatbot /> {/* Add the CustomChatbot component */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
