import React from 'react';
import './CompanyIntroduction.css';

function CompanyIntroduction() {
  return (
    <div className="company-introduction">
      <div className="intro-section">
        <h2>Who We Are</h2>
        <img src="/images/company-overview.jpg" alt="Company Overview" className="intro-image" />
        <p>
          TT Tech LTD is a London-based company specializing in IT services for
          financial companies and institutions worldwide. With extensive
          expertise and experience in the global financial market, we focus on
          financial data processing and IT solutions. Our commitment to
          innovation drives us to invest in cutting-edge IT companies across
          Europe.
        </p>
      </div>
      <div className="intro-section">
        <h2>What We Do</h2>
        <img src="/images/what-we-do.jpg" alt="What We Do" className="intro-image" />
        <p>
          We offer a comprehensive range of IT solutions, including:
        </p>
        <ul>
          <li>Securities Trading System</li>
          <li>Futures Trading System</li>
          <li>Forex/Bullion Trading System</li>
          <li>Backoffice System</li>
          <li>Market Data Solutions</li>
          <li>Listed Company Fundamental Data</li>
          <li>AI Investor Advisor Assistant Solutions</li>
        </ul>
        <p>
          Our services are designed to meet the unique needs of financial
          institutions, providing them with the tools and technologies required
          to excel in today's dynamic markets.
        </p>
      </div>
      <div className="intro-section">
        <h2>Our Mission</h2>
        <img src="/images/mission.jpg" alt="Our Mission" className="intro-image" />
        <p>
          Our mission is to make finance easy by providing innovative and
          effective IT solutions that simplify complex financial processes.
        </p>
      </div>
      <div className="intro-section">
        <h2>Our Vision</h2>
        <img src="/images/vision.jpg" alt="Our Vision" className="intro-image" />
        <p>
          Our vision is to be the leading financial IT company worldwide,
          recognized for our expertise, innovation, and dedication to customer
          success.
        </p>
      </div>
      <div className="intro-section">
        <h2>Our Values</h2>
        <img src="/images/values.jpg" alt="Our Values" className="intro-image" />
        <p>
          We are dedicated to creating value for our customers through integrity,
          excellence, and a commitment to continuous improvement.
        </p>
      </div>
    </div>
  );
}

export default CompanyIntroduction;
