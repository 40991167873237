import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './NewsAndInsights.css';

function NewsAndInsights() {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    // Fetch the news data from a local JSON file or a server endpoint
    const fetchNews = async () => {
      try {
        const response = await axios.get('/news.json'); // Adjust the path if necessary
        setArticles(response.data);
      } catch (error) {
        console.error('Error fetching news articles:', error);
      }
    };

    fetchNews();
  }, []);

  return (
    <div className="news-and-insights">
      <h2>News and Insights</h2>
      <div className="news-list">
        {articles.map((article, index) => (
          <div key={index} className="news-item">
            <img src={article.imageUrl} alt={article.title} className="news-image" />
            <div className="news-content">
              <h3>{article.title}</h3>
              <a href={article.url} target="_blank" rel="noopener noreferrer">Read more</a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default NewsAndInsights;
