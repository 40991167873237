import React from 'react';
import './AboutUs.css';

function AboutUs() {
  return (
    <div className="about-us">
      <section className="who-we-are">
        <h2>Who We Are</h2>
        <p>TT Tech LTD is a leading provider of financial IT and AI solutions, founded by experts in the field.</p>
      </section>
      <section className="our-founder">
        <h2>Our Founder</h2>
        <p>Our founder is a renowned expert in Financial IT, AI, and sales, with a proven track record of success.</p>
      </section>
      <section className="our-story">
        <h2>Our Story</h2>
        <p>Since our inception, we have been committed to delivering cutting-edge solutions to our clients.</p>
      </section>
    </div>
  );
}

export default AboutUs;
